import React from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

function Header () {
  return (
    <header
      style={{
        background: `white`,
        borderBottom: '1px solid black',
        position: 'relative',
        padding: '20px 20px 20px 20px'
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        py={{ xs: 0, sm: 1 }}
      >
        <Typography variant='h1' align='center' verticalAlign='center'>
          {'\u9999'}
        </Typography>
      </Box>
    </header>
  )
}

export default Header
