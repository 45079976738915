import React from 'react'
import {
  ArtistType,
  TechnologistType,
  ActiveStatusMessage
} from '../../../../constants'
import { renderSingleUserCell } from '../singleUserCell'
import { getUserTableCellData } from '../utils'
import ConfiguredMUIDatable from '../configuredMuiDatable'

export default function ActiveProposalsTable ({
  usersData,
  onErrorFunc,
  ...props
}) {
  const columns = [
    { label: 'Proposal ID', name: 'proposalID' },
    {
      label: 'Artist',
      name: 'artist',
      options: {
        customBodyRender: renderSingleUserCell
      }
    },
    {
      label: 'Technogologist',
      name: 'technologist',
      options: {
        customBodyRender: renderSingleUserCell
      }
    },
    { label: 'Deadline', name: 'submissionDeadline' },
    {
      label: 'Search',
      name: 'searchContent',
      options: {
        display: 'excluded',
        searchable: true
      }
    }
  ]
  return (
    <ConfiguredMUIDatable
      title={'Active Proposals'}
      data={createTableData(usersData)}
      columns={columns}
    />
  )
}

// *******************************************************
function createTableData (usersData) {
  // need to define it here because it must apply across users
  let selectorUUIDCache = {}
  return usersData.users
    .reduce(extractUniqueActiveProposals, [])
    .map(proposalToRowData)

  // *********************************************************
  function extractUniqueActiveProposals (arrayOfProposals, user) {
    user.proposals.forEach(function extractProposalIfActive (curProposal) {
      if (
        curProposal.activeOrUpcoming &&
        curProposal.statusMessage === ActiveStatusMessage &&
        selectorUUIDCache[curProposal.selectorUUID] !== true
      ) {
        arrayOfProposals.push(curProposal)
        selectorUUIDCache[curProposal.selectorUUID] = true
      }
    })
    return arrayOfProposals
  }

  function proposalToRowData (proposal) {
    let selector = usersData.usersIndexedByUUID[proposal.selectorUUID]
    let selectee = usersData.usersIndexedByUUID[proposal.selecteeUUID]

    // eslint-disable-next-line
    let artist = selector.craft == ArtistType ? selector : selectee
    // eslint-disable-next-line
    let technologist = selector.craft == TechnologistType ? selector : selectee

    let artistData = getUserTableCellData(artist)
    let technologistData = getUserTableCellData(technologist)

    return {
      artist: artistData,
      technologist: technologistData,
      proposalID: proposal.proposalID,
      submissionDeadline: proposal.submissionDeadline,
      searchContent: `${JSON.stringify(artistData)}${JSON.stringify(
        technologistData
      )}`
    }
  }
}
