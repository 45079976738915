import React from 'react'
import { Box } from '@material-ui/core/'
import { makeStyles } from '@material-ui/styles'
import { ArtistType } from '../../../constants'
import { HighlightSpecialText } from '../admin/utils'

const userStyles = makeStyles({
  details: {
    fontSize: '0.75rem',
    color: 'grey'
  }
})

export default function SingleUserCell ({
  userMeta,
  pl = 0,
  showCraftSuffix,
  showEmailAsLink,
  showEducationDetails,
  highlightText,
  joinFormSubmissionSearchMatches,
  ...props
}) {
  let safeJoinFormSubmissionSearchMatches = Array.isArray(
    joinFormSubmissionSearchMatches
  )
    ? joinFormSubmissionSearchMatches
    : []
  const c = userStyles()
  // eslint-disable-next-line
  let craftSuffix = userMeta.craft == ArtistType ? ' (A)' : ' (T)'
  return (
    <Box pl={pl}>
      <HighlightSpecialText
        mainText={userMeta.name}
        highlightText={highlightText}
      />
      {showCraftSuffix && craftSuffix}
      <br />

      <span className={c.details}>
        <HighlightSpecialText
          mainText={`uuid: ${userMeta.uuid}`}
          highlightText={highlightText}
        />
        <br />
        {showEmailAsLink ? (
          <a
            href={`mailto:${userMeta.email}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <HighlightSpecialText
              mainText={userMeta.email}
              highlightText={highlightText}
            />
          </a>
        ) : (
          <HighlightSpecialText
            mainText={userMeta.email}
            highlightText={highlightText}
          />
        )}
        {showEducationDetails && (
          <>
            <br />
            <HighlightSpecialText
              mainText={userMeta.university}
              highlightText={highlightText}
            />
            <br />
            <HighlightSpecialText
              mainText={userMeta.program}
              highlightText={highlightText}
            />
          </>
        )}
        <br />
        <br />
        <JoinFormSubmissionMatches
          matches={safeJoinFormSubmissionSearchMatches}
          highlightText={highlightText}
        />
      </span>
    </Box>
  )
}

export function renderSingleUserCell (value, tableMeta, updateValue) {
  return (
    <SingleUserCell
      userMeta={value}
      showEducationDetails
      highlightText={tableMeta.tableState.searchText || ''}
      joinFormSubmissionSearchMatches={getSearchMatchesInJoinFormSubmission(
        value,
        tableMeta.tableState.searchText
      )}
    />
  )
}

// **********************************************************
function getSearchMatchesInJoinFormSubmission (value, searchText) {
  let matches = []
  let safeSearchText = searchText || ''

  // Showing results for strings less than length 2 makes for a weird UI because
  // far too many strings show up in the view.
  if (safeSearchText.length <= 2) {
    return matches
  }

  for (let response of Object.values(value.joinFormSubmission)) {
    // at least one value is an array (areasOfInterest). This ensures we're always working with a string
    let trueText = `${response}`
    if (trueText.toLowerCase().includes(safeSearchText.toLowerCase())) {
      matches.push(`${response}`)
    }
  }
  return matches
}

function JoinFormSubmissionMatches ({ matches, highlightText }) {
  const title = 'Join Form Matches:'

  // eslint-disable-next-line
  if (matches.length == 0) {
    return null
  }

  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{title}</span>
      <br />
      {matches.map(renderJoinFormSubmissionMatch)}
    </>
  )
  // ***************************************************************
  function renderJoinFormSubmissionMatch (joinFormSubmissionSearchMatch) {
    return (
      <>
        <HighlightSpecialText
          mainText={joinFormSubmissionSearchMatch}
          highlightText={highlightText}
        />
        <br />
      </>
    )
  }
}
