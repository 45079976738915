import React from 'react'
import MUIDatable from 'mui-datatables'

// Add in some basic configs we want on all tables.
export default function ConfiguredMUIDatable ({
  title,
  data,
  columns,
  options,
  tableProps,
  ...props
}) {
  const fullOptions = {
    selectableRows: 'none',
    sortFilterList: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    ...options
  }
  return (
    <MUIDatable
      title={title}
      data={data}
      columns={columns}
      options={fullOptions}
      {...tableProps}
    />
  )
}
