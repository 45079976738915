import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import withLocation from './functionality/withLocation'
import { IsLoggedInHook } from '../../src/client/client'

// If a user tries to access an admin page without a login token, send em to the
// admin login page. If a logged in admin tries to access the /admin/login page,
// send them to /admin/unmatched.
function CheckAdminAuth ({ children, location, count = 0 }) {
  let [loggedIn, letsRender] = IsLoggedInHook(0)

  if (letsRender) {
    // If they're logged in and trying to acceess a pre-login page, redirect to post-logged in pages
    if (!isPostLoginAdminPath(location.pathname) && loggedIn) {
      navigate('/admin/unmatched')
      return null
    } else if (isPostLoginAdminPath(location.pathname) && !loggedIn) {
      // If they're not logged in and trying to access a post-login page, redirect
      // to a login page
      navigate('/admin')
      return null
    } else {
      // otherwise, just send them on in!
      return <>{children}</>
    }
  } else {
    return null
  }
}

function isPostLoginAdminPath (path) {
  return path.includes('admin') && !['/admin', '/admin/'].includes(path)
}

export default withLocation(CheckAdminAuth)
