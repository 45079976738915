import { useState } from 'react'
import { getAPIUrl } from './clientutils'
import Axios from 'axios'
import { useEffect } from 'react'

export function useStats () {
  // Define a clean initial return value so things dont break in the view consumption
  const emptyNumStatObj = {
    allTimeAverage: 0,
    name: '',
    description: ''
  }
  const emptyTableStatObj = {
    table: {},
    name: '',
    description: ''
  }
  const [stats, setStats] = useState({
    matchToll: emptyNumStatObj,
    proposalCompletionSpeed: emptyNumStatObj,
    proposalCompletionRate: emptyNumStatObj,
    crossSchoolMatches: emptyNumStatObj,
    studentsPerProgram: emptyTableStatObj
  })

  useEffect(function getStats () {
    let apiURL = getAPIUrl()
    Axios.get(`${apiURL}/admin/stats`, {
      ...getAuthorizationHeaders()
    }).then(function updateStats (response) {
      setStats(response.data)
    })
  }, [])

  return stats
}

export function MarkProposalReviewed (proposalID) {
  var apiURL = getAPIUrl()
  return Axios.get(
    `${apiURL}/admin/proposal?id=${proposalID}&action=reviewed`,
    {
      ...getAuthorizationHeaders()
    }
  )
}

// Sends a request to the remove match opportuntiy endpoint. Returns a promise so
// the consumer can decide what to do as a function of when and what it returns
export function RemoveMatchOpportunity (user1UUID, user2UUID) {
  var apiURL = getAPIUrl()
  return Axios.get(
    apiURL +
      `/admin/remove/matchOpportunity?user1UUID=${user1UUID}&user2UUID=${user2UUID}`,
    {
      ...getAuthorizationHeaders()
    }
  )
}

// Sends a request to the add match opportuntiy endpoint. Returns a promise so
// the consumer can decide what to do as a function of when and what it returns
export function AddMatchOpportunity (user1UUID, user2UUID) {
  var apiURL = getAPIUrl()
  return Axios.put(
    apiURL +
      `/admin/add/matchOpportunity?user1UUID=${user1UUID}&user2UUID=${user2UUID}`,
    {},
    {
      ...getAuthorizationHeaders()
    }
  )
}

function getUsers () {
  var apiURL = getAPIUrl()
  return Axios.get(apiURL + '/admin/users', {
    ...getAuthorizationHeaders()
  }).then(response => {
    return response.data
  })
}

export function UseUsers (refreshCount = 0) {
  const [usersData, setUsersData] = useState({
    users: [],
    usersIndexedByUUID: {}
  })
  useEffect(
    () => {
      getUsers().then(data => {
        setUsersData({
          users: data,
          usersIndexedByUUID: createUsersUUIDMap(data)
        })
      })
    },
    [refreshCount]
  )
  return usersData
}

export function InitiateMatchProcess (selectorUUID) {
  const apiURL = getAPIUrl()
  return Axios.post(
    apiURL + `/admin/initiate/matchProcess?selectorUUID=${selectorUUID}`,
    {},
    {
      ...getAuthorizationHeaders()
    }
  )
}

export function RemoveUser (uuid) {
  const apiURL = getAPIUrl()
  return Axios.delete(apiURL + `/admin/remove/user?uuid=${uuid}`, {
    ...getAuthorizationHeaders()
  })
}

export function SubmitEmail (email) {
  const apiURL = getAPIUrl()
  return Axios.post(`${apiURL}/submit/email?email=${email}`)
}

export function POSTLogin (password) {
  let apiUrl = getAPIUrl()
  return Axios.post(apiUrl + '/admin/login', {
    password: password
  })
}

export function IsLoggedInHook (count) {
  let apiURL = getAPIUrl()
  let [loggedIn, setLoggedIn] = useState(false)
  let [valueSet, setValueSet] = useState(false)

  useEffect(
    () => {
      Axios.get(apiURL + `/admin/isLoggedIn`, {
        ...getAuthorizationHeaders()
      }).then(
        function onFulfilled (response) {
          setLoggedIn(response.data === true)
          setValueSet(true)
        },
        function onRejected (response) {
          setValueSet(true)
        }
      )
    },
    [count]
  )
  return [loggedIn, valueSet]
}
// Use SetUserToken to set the JWT token for this user's session
export function SetUserToken (token) {
  localStorage.setItem('token', token)
}
// *****************************************************************************
function getUserToken () {
  return localStorage.getItem('token')
}
function getAuthorizationHeaders () {
  return {
    headers: { Authorization: `Bearer ${getUserToken()}` },
    withCredentials: true
  }
}

// Given an array of users, create a map from UUID to user name
function createUsersUUIDMap (users) {
  let usersIndexedBYUUID = {}
  users.forEach(user => (usersIndexedBYUUID[user.uuid] = user))

  return usersIndexedBYUUID
}
