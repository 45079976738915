import React from "react"
import ConfiguredMUIDatable from "../configuredMuiDatable"
import { renderSingleUserCell } from "../singleUserCell"
import { getUserTableCellData } from "../utils"

export default function ActiveMatchProcessesTable({ usersData }) {
  const columns = [
    {
      label: "Selector",
      name: "selector",
      options: {
        customBodyRender: renderSingleUserCell,
      },
    },
    {
      label: "Selectees",
      name: "selectees",
      options: {
        customBodyRender: renderSelecteesCell,
      },
    },
    {
      label: "Status",
      name: "status",
    },
  ]
  let options = {
    search: false,
  }
  return (
    <ConfiguredMUIDatable
      title={"Active Match Processes"}
      data={createTableData(usersData)}
      columns={columns}
      options={options}
    />
  )
}

// *********************************************************
function renderSelecteesCell(value, tableMeta, updateValue) {
  return value.map(function renderSelectee(selecteeData) {
    return (
      <>
        {selecteeData.email}
        <br />
      </>
    )
  })
}

function createTableData(usersData) {
    console.log('usersData', usersData);
  // need to define it out here because it must apply across users
  let selectorUUIDCache = {}
  return usersData.users
    .reduce(extractUniqueActiveMatchProcesses, [])
    .map(matchProcessToData)

  // ***********************************
  function extractUniqueActiveMatchProcesses(arrayOfMatchProcesses, user) {
    user.matchProcesses.forEach(function extractMatchProcessIfActive(
      matchProcess
    ) {
      if (
        matchProcess.inProcess &&
        selectorUUIDCache[matchProcess.selectorUUID] !== true
      ) {
        arrayOfMatchProcesses.push(matchProcess)
        selectorUUIDCache[matchProcess.selectorUUID] = true
      }
    })
    return arrayOfMatchProcesses
  }

  function matchProcessToData(matchProcess) {
    debugger
    return {
      selector: getUserTableCellData(
        usersData.usersIndexedByUUID[matchProcess.selectorUUID]
      ),
      // Include full data so we can extend the view in the future if need be
      selectees: matchProcess.selecteeUUIDs.map(
        function getSelecteeTableCellData(uuid) {
          return getUserTableCellData(usersData.usersIndexedByUUID[uuid])
        }
      ),
      status: matchProcess.statusMessage,
    }
  }
}
