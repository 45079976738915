import React from "react"

export function getActiveMatchProcessInWhichUserIsSelector(user) {
  debugger
  return user.matchProcesses.filter(
    mp => mp.inProcess && mp.selectorUUID === user.uuid
  )[0] // should only ever be length 1
}

// Replace any special characters with their escaped versions
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

export function HighlightSpecialText({ mainText, highlightText }) {
  // Get the start index and end index of highlightText in mainText
  const searchRegex = new RegExp(escapeRegExp(highlightText.toLowerCase()))
  const highlightStartIndex = mainText.toLowerCase().search(searchRegex)
  // Highlight text not in main text
  if (highlightStartIndex === -1) {
    return mainText
  } else {
    const highlightEndIndex = highlightStartIndex + highlightText.length
    return (
      <>
        {mainText.substring(0, highlightStartIndex)}
        <span style={{ backgroundColor: "lightgreen" }}>
          {mainText.substring(highlightStartIndex, highlightEndIndex)}
        </span>
        {mainText.substring(highlightEndIndex)}
      </>
    )
  }
}

export function getUserTableCellData(user) {
  if (!user) {
    user = {
      name: "NOT FOUND",
      email: "NOT FOUND",
      joinFormSubmission: { university: "NOT FOUND", program: "NOT FOUND" },
      uuid: "NOT FOUND",
      craft: "NOT FOUND",
      slackHandle: "NOT FOUND",
    }
  }
  return {
    name: user.name,
    email: user.email,
    university: user.joinFormSubmission.university,
    program: user.joinFormSubmission.program,
    uuid: user.uuid,
    craft: user.craft,
    joinFormSubmission: user.joinFormSubmission,
    slackHandle: user.slackHandle,
  }
}
