import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles({
  snackbarContentErrorRoot: {
    backgroundColor: 'red'
  },
  snackbarContentSuccessRoot: {
    backgroundColor: 'green'
  }
})

export function ErrorSnackBar ({ message, open, onClose }) {
  const c = styles()
  return (
    <Snackbar autoHideDuration={2500} open={open} onClose={onClose}>
      <SnackbarContent
        message={message}
        classes={{ root: c.snackbarContentErrorRoot }}
      />
    </Snackbar>
  )
}

export function SuccessSnackBar ({ message, open, onClose }) {
  const c = styles()
  return (
    <Snackbar autoHideDuration={2500} open={open} onClose={onClose}>
      <SnackbarContent
        message={message}
        classes={{ root: c.snackbarContentSuccessRoot }}
      />
    </Snackbar>
  )
}
