import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { UseUsers } from '../../client/client'
import Layout from '../../components/layout'
import UpcomingProposalsTable from '../../components/page/admin/inprocess/upcomingProposalsTable'
import ActiveProposalsTable from '../../components/page/admin/inprocess/activeProposalsTable'
import ActiveMatchProcessesTable from '../../components/page/admin/inprocess/activeMatchProcessesTable'
import { ErrorSnackBar } from '../../components/functionality/snackBars'

function ProposalsPage (props) {
  var usersData = UseUsers(0)

  // State variables that assist in managing success/error snack bars
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarErrorOpen, setSnackBarErrorOpen] = useState(false)

  return (
    <Layout>
      <Container maxWidth='md'>
        <Box py={2} />
        <ActiveProposalsTable
          usersData={usersData}
          onErrorFunc={displayErrorSnackBar}
        />
        <UpcomingProposalsTable usersData={usersData} />
        <ActiveMatchProcessesTable usersData={usersData} />
      </Container>
      <ErrorSnackBar
        message={snackBarMessage}
        open={snackBarErrorOpen}
        onClose={closeErrorSnackBar}
      />
    </Layout>
  )

  // *********************
  function displayErrorSnackBar (message) {
    setSnackBarErrorOpen(true)
    setSnackBarMessage(message)
  }

  function closeErrorSnackBar () {
    setSnackBarErrorOpen(false)
  }
}

export default ProposalsPage
