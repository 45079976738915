/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { createMuiTheme } from '@material-ui/core'
import ThemeProvider from '@material-ui/styles/ThemeProvider'

import Header from './header'
import CheckAdminAuth from './checkAdminAuth'
import './layout.css'

const theme = createMuiTheme({
  primary: {
    main: '#eeeeee'
  },
  typography: {
    h1: {
      fontSize: '50px',
      marginBottom: '-10px',
      fontFamily: 'ArialCustom7x7, Arial, sans-serif'
    },
    h4: {
      fontSize: '32px',
      fontWeight: '400',
      fontFamily: 'ArialCustom7x7, Arial, sans-serif'
    },
    h6: {
      fontSize: '24px',
      lineHeight: '30px',
      fontFamily: 'ArialCustom7x7, Arial, sans-serif'
    }
  }
})

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.up('sm')]: {
    fontSize: '75px',
    marginBottom: '-20px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '90px'
  }
}

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.up('sm')]: {
    fontSize: '36px'
  }
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <CheckAdminAuth>
      <Header siteTitle={data.site.siteMetadata.title} />
      <ThemeProvider theme={theme}>
        <main>{children}</main>
      </ThemeProvider>
    </CheckAdminAuth>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
